<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
require('fancybox')($)

export default {
  extends: GlobalVue,
  props: {
    title: {default:"File Preview"},
    rel: String,
    src: [String,Array],
    label: String,
  },
  data() {
    return {
      isOpen:false,
    }
  },
  computed:{
    isZip(){
      let src = Array.isArray(this.src) ? this.src : [this.src]
      var extension = ""
      src.forEach(href=>{
        extension = href.substr((href.lastIndexOf('.') +1))
      })
      return (['zip','rar'].indexOf(extension.toLowerCase()) > -1)
    }
  },
  mounted: function(){
  },
  methods:{
    openPopup(){
      this.isOpen = true
      var el = document.getElementById("body-overlay")
      el.classList.add("show")
    },
    closePopup(){
      this.isOpen = false
      console.log("close")
      var el = document.getElementById("body-overlay")
      el.classList.remove("show")
    }
  }
}
</script>

<template>
  <div>
    <div v-if="isOpen">
      <img :src="src" alt="" class="previewImage" srcset="">
      <a href="javascript:;" @click="closePopup">
        <i class="icon-cross closeImage"></i>
      </a>
    </div>
    <a href="javascript:;" @click="openPopup" v-if="$slots.default"><slot></slot></a>
    <a href="javascript:;" @click="openPopup" v-else class="btn_view">
    <slot><i :class="{'icon-image2':!isZip,'icon-file-download2':isZip}"></i> {{label}}</slot>
    </a>
  </div>
</template>